<template>
	<div>
		<Hero title="contact" class-name="contact" />
		<main>
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-12">
						<form v-if="isSuccess === false" v-on:submit.prevent="postSurvey" v-bind:class="{'was-validated': isSubmitted}">
							<p>
								Thank you for your interest in the life and ministry of Rabbi Yechiel Eckstein. <em>The Fellowship</em> is committed to listening
								to, learning from, and responding to your feedback.
							</p>
							<div class="form-row">
								<div class="form-group col-md-6">
									<label for="firstName">First Name</label>
									<input id="firstName" v-model="form.contact.firstName" type="text" class="form-control" required />
								</div>
								<div class="form-group col-md-6">
									<label for="lastName">Last Name</label>
									<input id="lastName" v-model="form.contact.lastName" type="text" class="form-control" required />
								</div>
							</div>
							<div class="form-row">
								<div class="form-group col-md-6">
									<label for="emailAddress">Email</label>
									<input id="emailAddress" v-model="form.contact.emailAddress" type="email" class="form-control" required />
								</div>
								<div class="form-group col-md-6">
									<label for="phoneNumber">Phone Number</label>
									<input id="phoneNumber" v-model="form.contact.phoneNumber" type="tel" class="form-control" required />
								</div>
							</div>

							<div class="form-row">
								<div class="form-group col-md-12">
									<label for="memory">Note</label>
									<textarea rows="6" id="note" v-model="form.note" class="form-control" required></textarea>
								</div>
							</div>
							<div class="submit">
								<div class="form-row justify-content-center">
									<div class="col-xs-12 col-sm-4">
										<button type="submit" class="btn btn-outline-primary btn-block" v-bind:disabled="isProcessing">
											Submit
											<span v-show="isProcessing"><i class="fas fa-spinner fa-pulse ml-2"></i></span>
										</button>
									</div>
								</div>
							</div>
						</form>
						<div v-if="isSuccess" class="alert alert-success text-center">
							Thank you for your feedback! We greatly appreciate your interest in the life and ministry of Rabbi Yechiel Eckstein, and your support for Israel and her people.
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Axios from 'axios';
import Hero from '@/components/Hero.vue';

export default {
	name: 'Contact',
	components: {
		Hero
	},
	data() {
		return {
			primaryId: 107,
			secondaryId: 1,
			motivationCode: window.motivationCode,
			emailId: this.$route.query.emid === undefined ? '' : this.$route.query.emid,
			form: {
				contact: {
					firstName: '',
					lastName: '',
					emailAddress: '',
					phoneNumber: '',
					streetAddress: '',
					city: '',
					state: '',
					country: '',
					postalCode: ''
				},
				note: ''
			},
			isSubmitted: false,
			isProcessing: false,
			isSuccess: false
		}
	},
	methods: {

		postSurvey() {

			this.isSubmitted = true;
			this.isProcessing = true;

			window.grecaptcha.ready(() => {
				window.grecaptcha.execute(window.reCAPTCHA.siteKey, {action: 'RabbiEcksteinContact'})
					.then((token) => {

						let config = {
							headers: {
								'reCAPTCHA-Token': token
							}
						};

						let payload = {
							formId: 0,
							primaryId: this.primaryId,
							secondaryId: this.secondaryId,
							emailId: this.emailId,
							motivationCode: this.motivationCode,
							contact: this.form.contact,
							note: this.form.note,
							subscriptions: []
						};

						Axios.post(window.baseApiUrl + '/forms/survey', payload, config)
							.then((response) => {
							
								this.isProcessing = false;
								this.isSuccess = true;

								return response;
							})
							.catch((error) => {

								this.isProcessing = false;

								return error;
							});

					});
			});
		}
	}
}
</script>

<style lang="scss" scoped>
</style>
